.VersionNumber-container {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    height: 100%;
    overflow: visible;
    z-index: 1;
    pointer-events: none;
}

.VersionNumber {
    color: rgb(118, 118, 118);
    filter: brightness(0.94);
    font-size: 1rem;
}