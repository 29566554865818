.MemeImages-container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: visible;
    z-index: 0;
    /*Clicking the container has no effect*/
    pointer-events: none;
}

.MemeImage {
    position: absolute;
    width: 16%;
    filter: brightness(0.94) blur(0.3px);
}

.MemeImage.red {
    top: 21.5%;
    left: 8%;
}

.MemeImage.blue {
    top: 27%;
    left: 72%;
    transform: scaleX(-1) rotate(45deg);
}

.MemeImage.scale {
    width: 26%;
    top: 14%;
    left: 35%;

    /* Pixelated and darken */
    filter: brightness(0.85) blur(0.8px);
    image-rendering: pixelated;
}

.MemeImage.cat {
    top: 21.5%;
    left: 8%;
    transform: scale(1.1);
}

.MemeImage.dog {
    top: 27%;
    left: 72%;
    transform: scale(0.85);
}