.DoomCounter-container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: visible;
    z-index: 0;
    /*Clicking the container has no effect*/
    pointer-events: none;
}

/* General Styles */
.DoomCounter {
    position: absolute;
    bottom: 8%;
    right: 5%;
    color: white;
    border-radius: 5%;
    /* Apply 3D Transformation */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.countdown {
    display: flex;
    justify-content: center;
    align-items: center;
    background: black;
    padding: 10px;
    border-radius: 5px;
    font-size: 1rem;
    gap: 10px;

    /*Mobile dimensions*/
    @media (max-width: 1200px) {
        font-size: 0.7rem;
        padding: 5px;
        border-radius: 5px;
        gap: 5px;
    }
}

.DoomCounter-emoji {
    font-size: 1.5rem;

    /*Mobile dimensions*/
    @media (max-width: 1200px) {
        font-size: 1rem;
    }
}

.time-unit {
    background: white;
    color: black;
    padding: 5px 10px;
    border-radius: 5px;
    font-family: 'Courier New', monospace;
    font-weight: bold;
}

.milliseconds {
    width: 50px;
    /* Adjust width to accommodate longer millisecond numbers */
}