.Scoreboard-container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    perspective: 500px;
    /* Adjust as needed for your view */
    perspective-origin: 50% 50%;
    /* Center the perspective */
    overflow: visible;
    /* Ensure that no clipping happens */
    z-index: 2;
    /*Clicking the container has no effect*/
    pointer-events: none;

}

/* General Styles */
.Scoreboard {
    position: absolute;
    width: 32%;
    height: 7%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1em;
    color: white;
    border-radius: 5%;
    /* Apply 3D Transformation */
    transform-style: preserve-3d;
    transform: rotateY(-20deg);

    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.Scoreboard.red {
    background-color: rgb(210, 0, 0);
    top: 11%;
    left: 10%;
}

.Scoreboard.blue {
    background-color: rgba(0, 0, 210, 1);
    top: 6.5%;
    left: 45%;
}