.top-bar {
    background-color: #f44336;
    /* Red background */
    color: white;
    /* White text */
    text-align: center;
    /* Center text */
    padding: 10px 0;
    /* Padding */
    width: 100%;
    /* Full width */
}

.top-bar p {
    margin: 0;
    /* Remove default margin */
    font-size: 1rem;
    /* Font size */

    /* Mobile */
    @media (max-width: 768px) {
        font-size: 1rem;
        /* Font size */
    }

    /* 4K */
    @media (min-width: 2560px) {
        font-size: 1.5rem;
        /* Font size */
    }
}