/* JumpingNumber.css */
.JumpingNumber {
    display: flex;
    font-size: 20px;
    transition: transform 0.3s ease-in-out;
    position: absolute;
    z-index: 1;
    text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.5);

    overflow: visible;
}

.JumpingNumber.red {
    color: red;
    bottom: 60%;
    left: 28%;
    transform-style: preserve-3d;
    transform: rotateY(-20deg);
}

.JumpingNumber.blue {
    color: blue;
    bottom: 70%;
    left: 62%;
    transform-style: preserve-3d;
    transform: rotateY(-20deg);
}

.JumpingNumber.pink {
    color: #ff009d;
    bottom: 6%;
    left: 5%;
    transform-style: preserve-3d;
    transform: rotateY(-20deg);
}

.JumpingNumber.purple {
    color: rgb(68, 6, 68);
    bottom: 50%;
    left: 50%;
    transform-style: preserve-3d;
    transform: rotateY(-20deg);
}

.JumpingNumber.white {
    color: #dcdcdc;
}

.jumping {
    animation: jump 0.1s ease-in-out;
}

@keyframes jump {
    0% {
        transform: scale(1) skew(0deg);
    }

    50% {
        transform: scale(1.2) skew(-10deg);
    }

    100% {
        transform: scale(1) skew(0deg);
    }
}