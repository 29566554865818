.ButtonZones-container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

/* .ButtonZones-zone {
    position: absolute;
    cursor: pointer;
    width: 18%;
    height: 13%;
    border-radius: 50%;
    z-index: 1;
    background-color: aqua;
}

.ButtonZones-zone.left {
    top: 17.5%;
    left: 24.5%;
}

.ButtonZones-zone.right {
    top: 12%;
    left: 57%;
} */

.ButtonZones-zone {
    position: absolute;
    cursor: pointer;
    width: 18%;
    height: 26%;
    border-radius: 50%;
    z-index: 1;
    /* background-color: rgba(0, 255, 255, 0.423); */
}

.ButtonZones-zone.left {
    top: 35%;
    left: 24.5%;
}

.ButtonZones-zone.right {
    top: 24%;
    left: 57%;
}