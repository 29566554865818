:root {
    --main-width: 500px;
    --main-height: 378px;
}

.App-main {
    text-align: center;
    position: relative;
    /* background-color: #000000; */
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    width: 100%;
    overflow: hidden;
    font-weight: bold;
}

.non-selectable {
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
}

.App-main::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;

    background-image:
        linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
        url('https://puushdabutton-web-images.s3.amazonaws.com/puush_button_background_image_funky.webp');
    background-size: cover;
    background-position: center;
}

.App-stuff-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: black;
    padding: 8px;
}


.App-image-container {
    position: relative;
    max-width: 100%;

    width: calc(var(--main-width) * 0.6);
    height: calc(var(--main-height) * 0.6);

    @media (min-width: 750px) {
        width: calc(var(--main-width) * 0.75);
        height: calc(var(--main-height) * 0.75);
    }

    @media (min-width: 1000px) {
        width: calc(var(--main-width) * 1);
        height: calc(var(--main-height) * 1);
    }

    @media (min-width: 1400px) {
        width: calc(var(--main-width) * 1.5);
        height: calc(var(--main-height) * 1.5);
    }
}

.App-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-user-drag: none;
    user-select: none;
}

.App-text-link {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #d8bc1b;
    font-size: 4rem;
    line-height: 1;
    margin-bottom: 10px;
    gap: 5px;

    /* smaller screens */
    @media (max-width: 750px) {
        font-size: 3rem;
    }
}

.App-text-link.underline {
    text-decoration: underline;
    cursor: pointer;
}

.App-copied-message {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #00a927;
    font-size: 2rem;
    margin-top: 1rem;
    z-index: 2;
    animation: Move-Up 1.1s ease-in-out;
}

/*Remove styles from <a>*/
a {
    text-decoration: none;
    color: inherit;
}

.App-contract {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #d84a1b;
    font-size: 2rem;
    margin-top: 1rem;
    cursor: pointer;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@keyframes Move-Up {
    from {
        transform: translateY(0px);
    }

    to {
        transform: translateY(-10px);
    }
}

/* Styles for the notConnected state, should make any component look slightly darker while also keeping its original color */
.notConnected {
    filter: brightness(0.5);
}